import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import '../assets/scss/main.scss'

const Layout = ({ children, title, location }) => {
  let content
  const pathname = location ? location.pathname : ''

  if (pathname === '/') {
    content = (
      <div>
        {children}
      </div>
    )
  } else {
    content = (
      <div id='wrapper' className='page'>
        <div>
          {children}
        </div>
      </div>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
              siteUrl
            }
          }
          background: file(name: {eq: "background"}) {
            publicURL
            childImageSharp {
              fixed(height: 630, width: 1200) {
                src
              }
            }
          }
        }
      `}
      render={({ site, background }) => (
        <>
          <Helmet
            htmlAttributes={{
              lang: 'en',
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
              {
                property: `url`,
                content: `${site.siteMetadata.siteUrl}${pathname}`,
              },
              {
                name: 'description',
                content: site.siteMetadata.description,
              },
              {
                name: 'keywords',
                content: 'roblox, game development',
              },
              {
                property: `image`,
                content: `${site.siteMetadata.siteUrl}${background.childImageSharp.fixed.src}`,
              },
              {
                property: `og:title`,
                content: site.siteMetadata.title,
              },
              {
                property: `og:description`,
                content: site.siteMetadata.description,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:site_name`,
                content: 'Sea of Voices',
              },
              {
                property: `og:image`,
                content: `${site.siteMetadata.siteUrl}${background.childImageSharp.fixed.src}`,
              },
            ]}
          >
            <html lang='en' />
          </Helmet>
          {content}
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
